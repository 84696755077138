import { render, staticRenderFns } from "./update-workflow.vue?vue&type=template&id=f5f784e0&scoped=true&"
import script from "./update-workflow.vue?vue&type=script&lang=js&"
export * from "./update-workflow.vue?vue&type=script&lang=js&"
import style0 from "./update-workflow.vue?vue&type=style&index=0&id=f5f784e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f784e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDialog,VIcon})
