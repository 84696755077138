<template>
  <div>
    <v-dialog
      v-model="updateDialog"
      :persistent="isUpdatingDialog"
      max-width="550px"
      transition="dialog-transition"
    >
      <div class="publish">
        <div class="publish__header">
          <span class="t">Update Workflow</span>
          <v-btn @click="updateDialog = false" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="publish__content">
          <span class="msg"
            >Confirm this workflow update is completed and ready for use</span
          >

          <v-btn
            color="primary"
            @click="UPDATE_WORKFLOW"
            elevation="1"
            x-large
            :loading="isUpdatingDialog"
          >
            <v-icon left>mdi-chevron-right</v-icon> Save</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="updateDialogSucessful"
      persistent
      max-width="550px"
      transition="dialog-transition"
    >
      <div class="publish-sucessful">
        <div class="publish-sucessful__header">
          <span class="t">Workflow Published</span>
          <v-btn @click="$emit('done')" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="publish-sucessful__top">
          <v-btn color="success" small fab outlined>
            <v-icon>check</v-icon>
          </v-btn>
          <span>Workflow updated successfully</span>
        </div>
        <div class="publish-sucessful__content">
          <span class="msg">
            Your workflow {{ workflow.workflow_title }}, was successfully saved
            and is ready to process data from your selected trigger.
          </span>

          <div class="mt-3">
            <v-btn
              color="primary"
              style="font-weight: bold"
              @click="showAdvance = !showAdvance"
              text
              >Advanced
              <v-icon v-if="!showAdvance" right>mdi-chevron-down</v-icon>
              <v-icon v-else>mdi-chevron-up</v-icon>
            </v-btn>

            <div v-if="showAdvance">
              <span
                style="color: #19283dcc; font-size: 16px"
                class="mb-2 mt-1 d-block"
              >
                To trigger this workflow using an external API or webhook
                service, make a POST request to the endpoint below
              </span>

              <!-- <v-text-field
                prefix="POST"
                v-model="workflow.webhook"
                disabled
                outlined
                append-icon="mdi-content-copy"
              ></v-text-field> -->

              <div class="api-cover">
                <button class="api-cover__req">POST</button>
                <div class="api-cover__url">{{ webhook }}</div>
                <v-btn
                  @click="clipboard(webhook)"
                  icon
                  style="margin-right: 15px"
                  ><v-icon>mdi-content-copy</v-icon></v-btn
                >
              </div>

              <span
                class="mt-1 d-block"
                style="color: #8f96a1; font-size: 14px"
              >
                <b>Note:</b> This endpoint is unique to this workflow and serves
                as a trigger. The payload must match the fields used in your
                workflow composition
              </span>
            </div>
          </div>

          <div class="mt-5 cta">
            <v-btn color="primary" @click="$emit('done')" elevation="0" large>
              <v-icon left>mdi-chevron-right</v-icon> close</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    workflow: {
      type: Object,
      default: () => ({}),
    },
    isSDK: {
      type: Boolean,
      default: false,
    },

    apiKey: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      updateDialog: false,
      updateDialogSucessful: false,
      isUpdatingDialog: false,
      showAdvance: false,
      webhook: "",
    };
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    start() {
      this.updateDialog = true;
    },

    async UPDATE_WORKFLOW() {
      this.isUpdatingDialog = true;
      try {
        const { data } = await this.$store.dispatch("workflow/updateWorkflow", {
          workflow: { id: this.id, ...this.workflow },
          apiKey: this.apiKey,
        });
        this.webhook = "http://flow.hypn.so/" + data.workflow_id;
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(data, null, 2));
        }
        this.updateDialog = false;
        this.updateDialogSucessful = true;
        this.$emit("success");
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(error, null, 2));
        }
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      } finally {
        this.isUpdatingDialog = false;
      }
    },

    clipboard(str) {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.showToast({
        sclass: "success",
        show: true,
        message: `copied ${str} to clipboard`,
        timeout: 3000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.publish {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__content {
    background-color: #fefcf8;
    padding: 30px 50px;
    text-align: center;

    .msg {
      font-size: 16px;
      color: #757575;
      line-height: 24px;
      display: block;
      margin-bottom: 30px;
    }

    #add-to-draft {
      display: block;
      margin: 50px auto 0px auto;
      background: transparent;
      color: #d7a47b;
      cursor: pointer;
      font-size: 17px;
      border-bottom: 1px solid #d7a47b;
    }
  }
}

.publish-sucessful {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__top {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 2px;
    padding: 20px;
    background-color: #f6f3ee;
    span {
      display: inline-block;
      margin-left: 10px;
      font-size: 21px;
      font-weight: bold;
      color: var(--primary-base);
    }
  }

  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    .msg {
      font-size: 16px;
      color: #757575;
      line-height: 24px;
      display: block;
      margin-bottom: 30px;
    }

    .cta {
      display: flex;
      justify-content: end;
    }
  }

  .api-cover {
    display: flex;
    align-items: center;
    border: 1px solid #19283d1a;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid rgba(25, 40, 61, 0.1);
    border-radius: 3px;
    margin: 10px 0px;

    &__req {
      height: 55px;
      padding: 0px 20px;
      background-color: #f4f5f6;
      color: #00233880;
      font-weight: bold;
      border-radius: 3px;
      font-family: "Inter";
    }

    &__url {
      padding: 0px 25px;
      flex: 1;
      color: #596a73;
    }
  }
}
</style>
